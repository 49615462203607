import axios from 'axios'
import VueCookies from 'vue-cookies'
import Vue from 'vue'
import {
	Message,
	Loading
} from 'element-ui'
Vue.use(VueCookies)
import router from './router/index.js'

import config from './config.js'

let loading;
let type;

function getFormData(object) {
	const formData = new FormData()
	Object.keys(object).forEach(key => {
		const value = object[key]
		if (Array.isArray(value)) {
			value.forEach((subValue, i) =>
				formData.append(key + `[${i}]`, subValue)
			)
		} else {
			formData.append(key, object[key])
		}
	})
	return formData
}

const service = axios.create({
	baseURL: config.baseURL,
	// baseURL:"http://localhost:3000/",
	timeout: 30000 // request timeout
})
service.interceptors.request.use(
	config => {
		// 
		if (config.params && VueCookies.get("userInfo")) {
			config.params.user_id = VueCookies.get("userInfo").user_id || ""
			config.params.user_token = VueCookies.get("userInfo").user_token || ""
		}
		if (config.data && VueCookies.get("userInfo")) {
			config.data.user_id = VueCookies.get("userInfo").user_id || ""
			config.data.user_token = VueCookies.get("userInfo").user_token || ""
			// if (config.type != 'json') {
			// 	
			// }else{
			// 	config.headers['Content-Type'] = 'application/json'
			// }
			config.data = getFormData(config.data)
			// config.headers['Content-Type'] = 'multipart/form-data'
		}
		if(config.params && config.params.type == 'getFile'){
			type = 'getFile'
		}else{
			type = 'normal'
		}
		// config.headers['token'] = VueCookies.get('token')
		!config.unLoading && (loading = Loading.service({
			lock: true,
			text: 'Loading',
			spinner: 'el-icon-loading',
			background: 'rgba(0, 0, 0, 0.7)'
		}))
		return config
	}, error => {
		console.log(error) // for debug
		return Promise.reject(error)
	}
)
// response interceptor
service.interceptors.response.use(
	response => {
		const res = response.data
		console.log(res)
		loading && loading.close()
		if(type == 'getFile'){
			return res
		}
		if (res.err_code == "SYS000") {
			return res
		} else if (res.err_code == "ACC005") {
			Message({
				message: '账号未登录',
				type: 'error',
				duration: 5 * 1000
			})
			// location.reload()
			router.push({
				path: '/login'
			})
			return Promise.reject(res.message)
		} else {
			Message({
				message: res.err_msg,
				type: 'error',
				duration: 5 * 1000
			})
			return Promise.reject(res.message)
		}
	}, error => {
		console.log('err' + error) // for debug
		Message({
			message: '请求失败，请重试',
			type: 'error',
			duration: 5 * 1000
		})
		loading.close()
		return Promise.reject(error)
	}
)
export default service
